import useSWR from "swr";
import useSWRImmutable from "swr/immutable";
import wretch from "wretch";
import QueryStringAddon from "wretch/addons/queryString";
import {
  FetcherOptions,
  Office,
  Paginated,
  PaginationQuery,
  Permission,
  ViewMode,
} from "../types";
import { formatYearMonth } from "../utils";

export namespace SP {
  export type MemberId = number;

  export type CompanyId = number;

  export type Luid = number;

  export interface EconomicAdvisor {
    luid: number;
  }

  export interface Privathaushalt {
    luid: number;
  }

  export interface Revisor {
    luid: number;
  }

  export interface Me {
    permissions: string[];
    economicAdvisor?: EconomicAdvisor;
    privateHousehold?: Privathaushalt;
    revisor?: Revisor;
  }

  export interface Member {
    id: MemberId;
    name: string;
    street: string;
    zip: string;
    city: string;
    taxCode: string;
    vatNumber: string;
    seacNumber?: string;
    giottoId?: number;
    luid?: number;
    permissions?: string[];
    companies?: Company[];
  }

  type Source = "admin" | "owner" | "member" | "company";

  export interface User {
    id: string;
    name: string;
    email: string;
    permissions?: Permission[];
    sources: Source[];
    companies: Assignment[];
  }

  interface Assignment {
    id: number;
    name?: string;
    permissions?: Permission[];
    sources: string[];
  }

  export interface WebdeskUser {
    login?: string;
  }

  export type InviteId = string;

  export interface Invite {
    id: InviteId;
    email: string;
    memberId: MemberId;
    memberName: string;
    companyId?: CompanyId;
    companyName?: string;
    expiresAt: string;
    declinedAt: string;
  }

  export interface EmergencyBody {
    giottoId: number;
    companyName: string;
    office: Office;
    phone: string;
    description: string;
  }

  export interface EmergencyStatus {
    active: boolean;
  }

  export interface YearMonth {
    year: number;
    month: number;
  }

  export interface Period {
    from: YearMonth;
    until: YearMonth;
  }

  export interface WageCosts {
    personalBeratungsNumber: number;
    year: number;
    month: number;
    workDays: number;
    daysAbsent: number;
    wage: number;
    overtimeHours: number;
    otherWage: number;
    dues: number;
    additionalSalaries: number;
    additionalSalaryDues: number;
    additionalPensionFund: number;
    abfertigungDesMonats: number;
    effektAnw: number;
    nurFamGes: number;
  }

  export type Level = "level1" | "level2" | "level3" | "level4";

  type ReturnType = "row" | "sum";

  export type ValueType = "absolute" | "currency" | "percentage";

  export interface Return<Value> {
    label: string;
    level: Level;
    type: ReturnType;
    valueType: ValueType;
    value: Value;
  }

  export type ValuePercent = [number, number];

  export interface TwoYearRow {
    year1: number;
    year2: number;
    [key: string]: number;
  }

  export interface ThreeYearRow {
    range1: ValuePercent;
    year1: ValuePercent | null;
    range2: ValuePercent;
    year2: ValuePercent | null;
    range3: ValuePercent;
    year3: ValuePercent | null;
  }

  export interface ThreeYearRowOld {
    year1: number;
    year2: number;
    year3: number;
    [key: string]: number;
  }

  export interface AnnualComparisonRow<T> {
    label: string;
    value: T;
  }

  export type AnnualComparisonOperationRow<T> =
    | { type: "summand"; value: AnnualComparisonRow<T> }
    | { type: "sum"; value: AnnualComparisonRow<T> };

  export type YearMonth2 = [number, number];

  export interface MonthlyDataRow {
    range1: [YearMonth2, ValuePercent][];
    range2: [YearMonth2, ValuePercent][];
  }

  export interface ChartRow {
    label: string;
    range1: ValuePercent;
    range2: ValuePercent;
    range3: ValuePercent;
  }

  export interface MonthlyChartRow {
    label: string;
    year: number;
    month: number;
    value: number;
  }

  export type CompanyTypeId = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;

  export interface Chart5Data {
    accountings: ChartRow[];
    companyTypeId: CompanyTypeId;
  }

  export interface Filter1Value {
    average: number;
    median: number;
  }

  export interface LineChartRow {
    code: string;
    date: Date | string;
    ownValue: number;
    filter1: Filter1Value;
  }

  export interface LineChartData {
    rows: LineChartRow[];
    filter1Count: number;
  }

  type FormatType =
    | "INTEGER"
    | "FLOAT"
    | "CURRENCY"
    | "TIME"
    | "PERCENT"
    | "STRING";

  interface KeyDataValue {
    ownValue: string | undefined;
    format: FormatType;
    filter1: Filter1Value;
  }

  export interface KeyDataRow {
    level: 1 | 2 | 3 | 4;
    code: string;
    value: KeyDataValue | null;
    companyCount: number | null;
    subRows: KeyDataRow[];
  }

  export interface KeyData {
    rows: KeyDataRow[];
    filter1Count: number;
  }

  export interface PieChartRow {
    key: string;
    value: number;
    comparisonCount: number;
  }

  export type ComparisonPieChartData = [PieChartRow[], PieChartRow[]];

  interface ReferralsTableRow {
    source: string;
    totalSessions: number;
  }

  export interface ReferralsTableData {
    rows: ReferralsTableRow[];
  }

  interface Company {
    id: CompanyId;
    name: string;
    street?: string;
    city?: string;
    zip?: string;
    luid?: number;
    permissions?: string[];
  }

  export type DocumentArchive = "payroll" | "accounting";

  export type DocumentSetType =
    | "lohn-rundschreiben"
    | "prov-lohnstreifen"
    | "lohnauswertung"
    | "zusaetzliche-auswertungen"
    | "einheitslohnbuch"
    | "matrikelbuch"
    | "arbeitsvertraege"
    | "antrag-um-familiengeld"
    | "zahlungsbestaetigung-f24"
    | "formblatt-770"
    | "formblatt-cu"
    | "jahresdokumente"
    | "steuer-rundschreiben"
    | "jahresauswertungen";

  export interface DocumentSet {
    type: DocumentSetType;
    unreadCount: number;
    month: string;
  }

  export type Department = "Steuer" | "Personal"

  export interface Document {
    id: string;
    readByCurrentUser: boolean;
    downloadLink: string;
    date: string;
    type: string;
    employee?: string;
    customer?: string;
    myGastroDocx: boolean;
    company?: number;
    member?: number;
    department?: Department;
    fileName: string
  }

  export interface FacetOption {
    id: string;
    displayName: string;
  }

  export interface DownloadLinks {
    zip: string | null;
    pdf: string | null;
    pdfWithoutMyGastroDocx: string | null;
  }

  export interface UseDocumentsResponse {
    downloadLinks: DownloadLinks;
    total: number;
    items: Document[];
    typeOptions: FacetOption[];
    dateOptions: FacetOption[];
    customerOptions: FacetOption[];
    companyOptions: FacetOption[];
    departmentOptions: FacetOption[];
    memberOptions: FacetOption[];
  }

  export interface OpenItem {
    name: string | null;
    vatCode: string;
    seacNumber: string;
    type: string;
    documentDate: string | null;
    documentNumber: string;
    documentNumberOriginal: string;
    operationDate: string | null;
    year: number;
    section: string;
    account: string;
    contraAccount: string;
    notes: string | null;
    description: string | null;
    balance: number;
  }

  export interface Country {
    alpha2Code: string;
    name: string;
    order?: number;
    isEuMember: boolean;
    locale?: string;
  }

  export namespace Dac7 {
    type MemberType = "company" | "soleProprietorship" | "privatePerson";

    interface LandRegistration {
      id: number;
      code?: string;
      sheet?: string;
      parcel?: string;
      subaltern?: string;
    }

    interface Address {
      street?: string;
      buildingIdentifier?: string;
      zip?: string;
      city?: string;
      country?: string;
      province?: string;
    }

    interface Company {
      id: SP.CompanyId;
      name: string;
      address?: Address;
      landRegistrations?: LandRegistration[];
    }

    export interface Member {
      id: SP.MemberId;
      type: MemberType;
      companyName?: string;
      firstName?: string;
      lastName?: string;
      fiscalCodeIssuedBy?: string;
      fiscalCode?: string;
      birthDate?: string;
      pec?: string;
      accountOwner?: string;
      iban?: string;
      vat?: string;
      rea?: string;
      permanentEstablishments?: string[];
      address?: Address;
      companies?: Company[];
    }

    interface PutAddress {
      street?: string;
      buildingIdentifier?: string;
      zip: string;
      city: string;
      country: string;
      province: string;
    }

    export interface PutMemberLandRegistration {
      id?: number;
      code?: string;
      sheet?: string;
      parcel?: string;
      subaltern?: string;
    }

    interface PutMemberCompany {
      id: SP.CompanyId;
      address: PutAddress;
      landRegistrations: PutMemberLandRegistration[];
    }

    export interface PutMemberBody {
      fiscalCodeIssuedBy: string;
      fiscalCode: string;
      birthDate?: string;
      pec: string;
      accountOwner: string;
      iban: string;
      vat?: string;
      rea?: string;
      permanentEstablishments: string[];
      address: PutAddress;
      companies: PutMemberCompany[];
    }
  }

  export interface Customer {
    name: string;
    freelanceInvoices: boolean;
    taxCode?: string;
  }

  export interface Questionnaire {
    year: number;
    customers: Customer[];
  }

  export interface PutQuestionnaireBody extends Questionnaire {}
}

const api = wretch()
  .addon(QueryStringAddon)
  .options({ credentials: "include" })
  .url("/api");

const fetcher = <Response>({ url, query, signal }: FetcherOptions) => {
  return api
    .options({ signal })
    .url(url)
    .query(query ?? {})
    .get()
    .json<Response>();
};

export const useMe = () =>
  useSWRImmutable({ url: "/me" }, (opts) => fetcher<SP.Me>(opts));

export const useMembers = (query: PaginationQuery, signal?: AbortSignal) =>
  useSWRImmutable({ url: "/members", query, signal }, (opts) =>
    fetcher<{ items: SP.Member[] }>(opts)
  );

export const useMember = (id: SP.MemberId | undefined, signal: AbortSignal) =>
  useSWRImmutable(id ? { url: `/members/${id}`, signal } : null, (opts) =>
    fetcher<SP.Member>(opts)
  );

export const getMember = (id: SP.MemberId) =>
  api.get(`/members/${id}`).json<SP.Member>();

export const useUsers = (
  id: SP.MemberId | undefined,
  query: PaginationQuery,
  signal: AbortSignal
) =>
  useSWRImmutable(
    id ? { url: `/members/${id}/users`, query, signal } : null,
    (opts) => fetcher<Paginated<{ items: SP.User[] }>>(opts)
  );

export const useUser = (
  memberId: SP.MemberId | undefined,
  userId: string,
  signal: AbortSignal
) =>
  useSWRImmutable(
    memberId ? { url: `/members/${memberId}/users/${userId}`, signal } : null,
    (opts) => fetcher<SP.User>(opts)
  );

export const putMemberUser = (
  memberId: SP.MemberId,
  companyId: SP.CompanyId | undefined,
  userId: string,
  isOwner: boolean,
  permissions: string[]
) =>
  api
    .url(`/members/${memberId}/users/${userId}`)
    .put({
      companyId,
      isOwner,
      permissions,
    })
    .res();

export const deleteMemberUser = (
  memberId: SP.MemberId,
  companyId: SP.CompanyId | undefined,
  userId: string
) =>
  api
    .url(`/members/${memberId}/users/${userId}`)
    .query({ ...(companyId && { company_id: companyId }) })
    .delete()
    .res();

export const useWebdeskUser = (
  memberId: SP.MemberId | undefined,
  userId: string,
  signal: AbortSignal
) =>
  useSWRImmutable(
    memberId
      ? { url: `/members/${memberId}/users/${userId}/webdesk`, signal }
      : null,
    (opts) => fetcher<SP.WebdeskUser>(opts)
  );

export const putWebdeskUser = (
  memberId: SP.MemberId,
  userId: string,
  webdeskUser: SP.WebdeskUser
) =>
  api
    .url(`/members/${memberId}/users/${userId}/webdesk`)
    .put(webdeskUser)
    .json<SP.WebdeskUser>();

export const useMemberInvites = (
  id: SP.MemberId | undefined,
  query: PaginationQuery,
  signal: AbortSignal
) =>
  useSWRImmutable(
    id ? { url: `/members/${id}/invites`, query, signal } : null,
    (opts) => fetcher<Paginated<{ items: SP.Invite[] }>>(opts)
  );

export const upsertMemberInvite = (
  memberId: SP.MemberId,
  companyId: SP.CompanyId | undefined,
  name: string,
  email: string,
  isOwner: boolean,
  permissions: string[]
) =>
  api
    .url(`/members/${memberId}/invites`)
    .put({ companyId, name, email, isOwner, permissions })
    .json();

export const deleteMemberInvite = (memberId: number, inviteId: string) =>
  api.url(`/members/${memberId}/invites/${inviteId}`).delete().res();

export const useInvites = (query: PaginationQuery, signal?: AbortSignal) =>
  useSWRImmutable({ url: "/invites", query, signal }, (opts) =>
    fetcher<Paginated<{ items: SP.Invite[] }>>(opts)
  );

export const usePendingInvitesCount = () =>
  useSWR({ url: "/invites", query: { page: 1, limit: 1 } }, (opts) =>
    fetcher<Paginated<{ items: SP.Invite[] }>>(opts).then((r) => r.total)
  );

export const acceptInvite = (id: SP.InviteId) =>
  api.url(`/invites/${id}/accept`).put().res();

export const declineInvite = (id: SP.InviteId) =>
  api.url(`/invites/${id}/decline`).put().res();

export const useAdminInvites = (query: PaginationQuery, signal?: AbortSignal) =>
  useSWRImmutable({ url: "/admin/invites", query, signal }, (opts) =>
    fetcher<Paginated<{ items: SP.Invite[] }>>(opts)
  );

export const useAdminUsers = (query: PaginationQuery, signal?: AbortSignal) =>
  useSWRImmutable({ url: "/admin/users", query, signal }, (opts) =>
    fetcher<
      Paginated<{
        items: (SP.User & { firstName: string; lastName: string })[];
      }>
    >(opts)
  );

export const useAdminUser = (id: string | undefined, signal?: AbortSignal) =>
  useSWRImmutable(id ? { url: `/admin/users/${id}`, signal } : null, (opts) =>
    fetcher<SP.User & { firstName: string; lastName: string }>(opts)
  );

export const deleteAdminUser = (id: string) =>
  api.url(`/admin/users/${id}`).delete().res();

export const useAdminUserMembers = (
  id: string,
  query: PaginationQuery,
  signal?: AbortSignal
) =>
  useSWRImmutable(
    { url: `/admin/users/${id}/members`, query, signal },
    (opts) => fetcher<{ items: SP.Member[] }>(opts)
  );

export const postEmergency = (body: SP.EmergencyBody) =>
  api.url(`/emergencies`).post(body).res();

export const useEmergencyStatus = () =>
  useSWRImmutable({ url: "/emergencies/status" }, (opts) =>
    fetcher<SP.EmergencyStatus>(opts)
  );

export const useWageCosts = (
  memberId: number | undefined,
  giottoId: number | undefined,
  { from, until }: SP.Period,
  signal: AbortSignal
) =>
  useSWRImmutable(
    giottoId
      ? {
        url: `/wage-costs/${giottoId}`,
        query: {
          member_id: memberId,
          from: formatYearMonth(from),
          until: formatYearMonth(until),
        },
        signal,
      }
      : null,
    (opts) => fetcher<SP.WageCosts[]>(opts)
  );

export const getWageCosts = (
  memberId: number,
  giottoId: number,
  { from, until }: SP.Period
) =>
  api
    .url(`/wage-costs/${giottoId}`)
    .query({
      member_id: memberId,
      from: formatYearMonth(from),
      until: formatYearMonth(until),
    })
    .get()
    .json<SP.WageCosts[]>();

export const useProfitLosses = (
  memberId: SP.MemberId | undefined,
  seacNumber: string | undefined,
  companyId: SP.CompanyId | undefined,
  { from, until }: SP.Period,
  include13: boolean,
  isCompetence: boolean,
  signal: AbortSignal
) =>
  useSWRImmutable(
    seacNumber
      ? {
        url: `/profit-loss/${seacNumber}/accountings`,
        query: {
          ...(memberId && { member_id: memberId }),
          from: formatYearMonth(from),
          until: formatYearMonth(until),
          include_13: include13,
          is_competence: isCompetence,
          ...(companyId && { company_id: companyId }),
        },
        signal,
      }
      : null,
    (opts) => fetcher<SP.Return<SP.ThreeYearRow>[]>(opts)
  );

export const getProfitLossesExcel = (
  memberId: SP.MemberId,
  seacNumber: string,
  companyId: SP.CompanyId | undefined,
  { from, until }: SP.Period,
  include13: boolean,
  isCompetence: boolean
) =>
  api
    .url(`/profit-loss/${seacNumber}/export/accountings`)
    .query({
      member_id: memberId,
      from: formatYearMonth(from),
      until: formatYearMonth(until),
      include_13: include13,
      is_competence: isCompetence,
      ...(companyId && { company_id: companyId }),
    })
    .get()
    .res();

export const useMonthlyData = (
  memberId: SP.MemberId | undefined,
  seacNumber: string | undefined,
  companyId: SP.CompanyId | undefined,
  { from, until }: SP.Period,
  include13: boolean,
  isCompetence: boolean,
  signal: AbortSignal
) =>
  useSWRImmutable(
    seacNumber
      ? {
        url: `/monthly-data/${seacNumber}/accountings`,
        query: {
          ...(memberId && { member_id: memberId }),
          from: formatYearMonth(from),
          until: formatYearMonth(until),
          include_13: include13,
          is_competence: isCompetence,
          ...(companyId && { company_id: companyId }),
        },
        signal,
      }
      : null,
    (opts) => fetcher<SP.Return<SP.MonthlyDataRow>[]>(opts)
  );

export const getMonthlyDataExcel = (
  memberId: SP.MemberId,
  seacNumber: string,
  companyId: SP.CompanyId | undefined,
  { from, until }: SP.Period,
  include13: boolean,
  isCompetence: boolean
) =>
  api
    .url(`/monthly-data/${seacNumber}/export/accountings`)
    .query({
      member_id: memberId,
      from: formatYearMonth(from),
      until: formatYearMonth(until),
      include_13: include13,
      is_competence: isCompetence,
      ...(companyId && { company_id: companyId }),
    })
    .get()
    .res();

export const useChart1Data = (
  memberId: SP.MemberId,
  seacNumber: string,
  { from, until }: SP.Period,
  showVerwalterentschaedigung: boolean,
  isCompetence: boolean,
  companyId: SP.CompanyId | undefined,
  signal: AbortSignal
) =>
  useSWRImmutable(
    seacNumber
      ? {
        url: `/charts/${seacNumber}/chart1`,
        query: {
          member_id: memberId,
          from: formatYearMonth(from),
          until: formatYearMonth(until),
          show_verwalterentschaedigung: showVerwalterentschaedigung,
          is_competence: isCompetence,
          ...(companyId && { company_id: companyId }),
        },
        signal,
      }
      : null,
    (opts) => fetcher<SP.ChartRow[]>(opts)
  );

export const useChart2Data = (
  memberId: SP.MemberId,
  seacNumber: string,
  { from, until }: SP.Period,
  showVerwalterentschaedigung: boolean,
  isCompetence: boolean,
  companyId: SP.CompanyId | undefined,
  signal: AbortSignal
) =>
  useSWRImmutable(
    seacNumber
      ? {
        url: `/charts/${seacNumber}/chart2`,
        query: {
          member_id: memberId,
          from: formatYearMonth(from),
          until: formatYearMonth(until),
          show_verwalterentschaedigung: showVerwalterentschaedigung,
          is_competence: isCompetence,
          ...(companyId && { company_id: companyId }),
        },
        signal,
      }
      : null,
    (opts) => fetcher<SP.ChartRow[]>(opts)
  );

export const useChart3Data = (
  memberId: SP.MemberId,
  seacNumber: string,
  { from, until }: SP.Period,
  isCompetence: boolean,
  companyId: SP.CompanyId | undefined,
  signal: AbortSignal
) =>
  useSWRImmutable(
    seacNumber
      ? {
        url: `/charts/${seacNumber}/chart3`,
        query: {
          member_id: memberId,
          from: formatYearMonth(from),
          until: formatYearMonth(until),
          is_competence: isCompetence,
          ...(companyId && { company_id: companyId }),
        },
        signal,
      }
      : null,
    (opts) => fetcher<SP.MonthlyChartRow[]>(opts)
  );

export const useChart4Data = (
  memberId: SP.MemberId,
  seacNumber: string,
  { from, until }: SP.Period,
  companyId: SP.CompanyId | undefined,
  signal: AbortSignal
) =>
  useSWRImmutable(
    seacNumber
      ? {
        url: `/charts/${seacNumber}/chart4`,
        query: {
          member_id: memberId,
          from: formatYearMonth(from),
          until: formatYearMonth(until),
          ...(companyId && { company_id: companyId }),
        },
        signal,
      }
      : null,
    (opts) => fetcher<SP.MonthlyChartRow[]>(opts)
  );

export const useChart5Data = (
  memberId: SP.MemberId,
  seacNumber: string,
  { from, until }: SP.Period,
  isCompetence: boolean,
  companyId: SP.CompanyId | undefined,
  signal: AbortSignal
) =>
  useSWRImmutable(
    seacNumber && companyId
      ? {
        url: `/charts/${seacNumber}/chart5`,
        query: {
          member_id: memberId,
          from: formatYearMonth(from),
          until: formatYearMonth(until),
          is_competence: isCompetence,
          company_id: companyId,
        },
        signal,
      }
      : null,
    (opts) => fetcher<SP.Chart5Data>(opts)
  );

export const useMetrics = (
  memberId: SP.MemberId | undefined,
  companyId: SP.CompanyId | undefined,
  viewMode: ViewMode,
  { from, until }: SP.Period,
  signal: AbortSignal
) =>
  useSWRImmutable(
    companyId
      ? {
        url: `/website-metrics/${companyId}/reports/benchmark/metrics`,
        query: {
          member_id: memberId,
          from: formatYearMonth(from),
          until: formatYearMonth(until),
          view_mode: viewMode,
        },
        signal,
      }
      : null,
    (opts) => fetcher<SP.LineChartData>(opts)
  );

export const useKeyData = (
  memberId: SP.MemberId | undefined,
  companyId: SP.CompanyId | undefined,
  { from, until }: SP.Period,
  signal: AbortSignal
) =>
  useSWRImmutable(
    companyId
      ? {
        url: `/website-metrics/${companyId}/reports/benchmark/key-data`,
        query: {
          member_id: memberId,
          from: formatYearMonth(from),
          until: formatYearMonth(until),
        },
        signal,
      }
      : null,
    (opts) => fetcher<SP.KeyData>(opts)
  );

export const useReferralsTableData = (signal: AbortSignal) =>
  useSWRImmutable(
    {
      url: `/website-metrics/reports/referrals`,
      signal,
    },
    (opts) => fetcher<SP.ReferralsTableData>(opts)
  );

export const useDocumentSets = (
  archive: SP.DocumentArchive,
  luid: SP.Luid | undefined,
  signal?: AbortSignal
) =>
  useSWRImmutable(
    { url: `/documents/${archive}/${luid}/sets`, signal },
    (opts) => fetcher<SP.DocumentSet[]>(opts)
  );

export const useDocuments = (
  archive: SP.DocumentArchive,
  luid: SP.Luid,
  setType: SP.DocumentSetType,
  {
    page,
    limit,
    type,
    date,
    employee,
    customer,
    department,
    company,
  }: PaginationQuery<{
    type?: string;
    date?: string;
    employee?: string;
    customer?: string;
    department?: string;
    company?: string;
  }>,
  signal?: AbortSignal
) =>
  useSWRImmutable(
    {
      url: `/documents/${archive}/${luid}`,
      query: {
        set: setType,
        page,
        limit,
        ...(type && { type }),
        ...(date && { date }),
        ...(employee && { employee }),
        ...(customer && { customer }),
        ...(department && { department }),
        ...(company && { company }),
      },
      signal,
    },
    (opts) => fetcher<SP.UseDocumentsResponse>(opts)
  );

export const getDocument = (path: string) =>
  api.url("/documents/export").query({ path }).get().res();

export const useOpenItems = (memberId: SP.MemberId, seacNumber: string) =>
  useSWRImmutable(
    { url: `/open-items/${seacNumber}`, query: { member_id: memberId } },
    (opts) => fetcher<SP.OpenItem[]>(opts)
  );

export const getWebdeskUrl = (
  app: "stundenregister" | "mitarbeitermeldung",
  memberId: SP.MemberId
) => api.url(`/auth/webdesk/${app}`).post({ memberId }).text();

export const getMetropolisUrl = (memberId: SP.MemberId) =>
  api.url("/auth/metropolis").post({ memberId }).text();

export const useDac7Member = (memberId: SP.MemberId | undefined) =>
  useSWRImmutable(
    memberId ? { url: `/dac7/members/${memberId}` } : null,
    (opts) => fetcher<SP.Dac7.Member>(opts)
  );

export const putDac7Member = (
  memberId: SP.MemberId,
  body: SP.Dac7.PutMemberBody
) => api.url(`/dac7/members/${memberId}`).put(body).json<SP.Dac7.Member>();

export const useCountries = () =>
  useSWRImmutable({ url: "/countries" }, fetcher<SP.Country[]>);

export const useQuestionnaire = (
  luid: SP.Luid,
  year: number,
  signal?: AbortSignal
) =>
  useSWRImmutable(
    { url: `/economic_advisors/${luid}/questionnaire/${year}`, signal },
    (opts) => fetcher<SP.Questionnaire>(opts)
  );

export const putQuestionnaire = (
  luid: SP.Luid,
  body: SP.PutQuestionnaireBody
) =>
  api
    .url(`/economic_advisors/${luid}/questionnaire`)
    .put(body)
    .json<SP.Questionnaire>();
